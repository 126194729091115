<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="faq">
            <div class="faq-banner">
                <div class="faq-banner-image">
                    <img
                        src="@/assets/images/room-2100820_1920@2x.jpg"
                        alt="FAQ"
                    />
                </div>
                <div class="faq-banner-details">
                    <div class="faq-banner-text">
                        FAQ
                    </div>
                </div>
            </div>
            <div class="faq-details">
                <div v-for="(faq, j) in faqs" :key="j">
                    <p style="margin-top: 60px;">
                        <span class="category-number">{{ j + 1 }}.</span>
                        <span
                            class="cateogry-name"
                            style="text-decoration: underline;margin-left: 20px;"
                            >{{ faq.category }}</span
                        >
                    </p>
                    <div v-for="(question, i) in faq.questions" :key="i">
                        <FAQAccordion
                            :index="i"
                            :faq="question.text"
                            :answer="question.answer"
                            :categoryNumber="j + 1"
                            :questionNumber="i + 1"
                            :open="question.open"
                            @toggleOpen="toggleOpen"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import FAQAccordion from 'componentsv2/FAQAccordion'
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'FAQPage',
    components: {
        FAQAccordion,
        Footer,
        Loader,
    },
    mixins: [loaderHandler],
    data() {
        return {
            faqs: [
                {
                    category:
                        'Home Furnishings & Interior Design, Interior Designer',
                    questions: [
                        {
                            text:
                                'Do I need professional help in designing my new home?',
                            answer: `You are the best judge of your own space and your vision matters the most. But hiring an interior designer would help you design your home in a much more efficient manner.A few ways in which an interior designer can help you are:
                        1.) Saves Money- Hiring a designer can help you to avoid costly mistakes that will not only help you save money but can increase the value of your home.
                        2.) Professional Assessment- A designer can assess the space and guide you regarding the best utilisation of the space keeping your requirements in mind.
                        3.) Budgeting and planning- Once you've set your budget, a designer will make sure to adhere to it along with the timeline given. The entire work schedule would save you time and effort of going back forth every single time something new is added.
                        4.) Bridging the gap- an interior designer acts like a bridge between you and various stakeholders working on site bringing your dream home to life.
                        5.) Resources and contacts- Being in the industry, an interior designer has multiple tie ups and affiliations with different vendors which can again give you a variety of options at your doorstep with minimum time and effort put in.
                        6.) Wow Factor- they can help you achieve that wow factor which you've been looking for in your space as they are trained to think differently, spatially, and to see an overall picture.
                    `,
                            open: false,
                        },
                        {
                            text: 'How much does an interior designer cost?',
                            answer:
                                'At Siloho, hiring an interior designer costs much less than you would expect.',
                            open: false,
                        },
                        {
                            text:
                                'Can I afford an interior designer within my limited budget?',
                            answer:
                                'Yes, you can. Good design does not need to be expensive but functional and aesthetically pleasing.',
                            open: false,
                        },
                        {
                            text: 'What constitutes a well-designed home?',
                            answer: `A well-designed home is a thoughtfully yet tastefully designed home which maps the residents' day to day routine and needs.`,
                            open: false,
                        },
                        {
                            text:
                                'What kind of home furnishing items can I find at SILOHO?',
                            answer:
                                'Every kind from mattress to curtains and more.',
                            open: false,
                        },
                        {
                            text:
                                'Do you have a particular style of interior design or can I expect a variety of themes?',
                            answer:
                                'Our aim is to design for the user. Hence, we provide a variety of design styles from which the user can find their perfect match.',
                            open: false,
                        },
                        {
                            text:
                                'How do you show the interior designs and home furnishings?',
                            answer: `We show a realistic 3d image of the user's home with all the selected design elements and furnishings.`,
                            open: false,
                        },
                        {
                            text:
                                'What if I am not happy with the design I get?',
                            answer:
                                'No issues, simply let us know and we will fix it.',
                            open: false,
                        },
                        {
                            text:
                                'What separates your service in home furnishings from the rest of the vendors?',
                            answer:
                                'We are not just a home furnishing company, we are a design company as well. And we specialised in from end to end execution- from design to complete furnishing of your perfect home.',
                            open: false,
                        },
                        {
                            text:
                                'Do you have top interior designers working with you?',
                            answer:
                                'We have a range of interior designers onboarded, from luxury to budget-friendly.',
                            open: false,
                        },
                        {
                            text:
                                'What if I am interested in a specific product, which I cannot find on your website?',
                            answer: `You may contact us with that product's information and we would try our best to get it for you on our platform. `,
                            open: false,
                        },
                        {
                            text:
                                'I have just purchased my own home, can you ensure that the rest of the interior costing will be budget-friendly?',
                            answer: `Yes, we try to adhere to our client's budget restrictions and suggest products accordingly.`,
                            open: false,
                        },
                        {
                            text:
                                'Apart from budget-friendly and space-optimised interiors, why should I hire SILOHO?',
                            answer:
                                'Your home Is your fortress where you create happy memories and also soothe yourself during difficult times. Designing your perfect space is not indulgent living, but a step towards mindful existence.',
                            open: false,
                        },
                        {
                            text:
                                'Do you only take care of home projects or is SILOHO open to office projects as well? ',
                            answer: 'As of now, only home projects.',
                            open: false,
                        },
                        {
                            text:
                                'Can I buy merely one item and not all the suggestions for the home furnishings?',
                            answer: 'Yes, you can.',
                            open: false,
                        },
                        {
                            text:
                                'What options do you offer for measurement? How will I figure out what my room dimensions are?',
                            answer:
                                'You may provide us with your house layout if you have or else we can measure it for you.',
                            open: false,
                        },
                        {
                            text:
                                'How many days does it take to receive the initial design concepts and how much time does it usually take for the final designs to be delivered?',
                            answer:
                                'Depends on the design but we will be constantly in touch and will inform you in advance about the timelines.',
                            open: false,
                        },
                        {
                            text:
                                'How do you assure that I will get the right interior designer as per my preference of designs? ',
                            answer:
                                'After you take our home-style quiz and understand your home decor personality, we will help you select the right designer based on your needs.',
                            open: false,
                        },
                        {
                            text:
                                'How can SILOHO help me to save my expenses on furniture?',
                            answer:
                                'Siloho has tie-ups with multiple furniture vendors and aims to give you the most competitive prices on our platform.',
                            open: false,
                        },
                    ],
                },
                {
                    category: 'Payments, Transactions and Shipment with Siloho',
                    questions: [
                        {
                            text:
                                'How do I finalise and make payments for my products sourced via Siloho?',
                            answer:
                                'Once the design presentation is finalised along with the list of furnishing items, a detailed BOQ (bill of quantities) will be sent to the client and phases of payment would be mentioned below, according to which the payments can be made to Siloho.',
                            open: false,
                        },
                        {
                            text: 'Which payment methods do you accept?',
                            answer: 'Account transfer: IMPS, RTGS, NEFT.',
                            open: false,
                        },
                        {
                            text:
                                'After I make the payment, what is the order confirmation communication I will be receiving?',
                            answer:
                                'You’ll receive an order confirmation email from us along with the transaction ref id.',
                            open: false,
                        },
                        {
                            text:
                                'What is the delivery timeline you promise and what are the delivery charges?',
                            answer:
                                'The delivery timeline varies from 30days to 90days depending on the products selected by you and their respective delivery timelines. From our end, we try to pick only those products that fall under the given timeline.',
                            open: false,
                        },
                        {
                            text: 'How can I track my order?',
                            answer:
                                'Weekly updates will be given by the site supervisor and a tracking id will be provided to the client.',
                            open: false,
                        },
                        {
                            text:
                                'Are there any terms Siloho follows for product handling? ',
                            answer:
                                'We provide site supervision as a part of our services where our supervisor would be on-site to receive all deliveries and check if there’s any damage or not.',
                            open: false,
                        },
                        {
                            text: 'Can I opt for Cash on Delivery?',
                            answer:
                                'Unfortunately, we don’t have that payment option as of now.',
                            open: false,
                        },
                        {
                            text:
                                'Will there be any taxes which I am charged for?',
                            answer:
                                'Taxes would only be applicable on services, apart from that all products would be as per actuals.',
                            open: false,
                        },
                        {
                            text:
                                'Do you have any exclusive discounts if I purchase the furniture from Siloho and not the concerned vendor?',
                            answer:
                                'Yes, we have affiliations with various vendors across the country which offer competitive pricing/cash backs.',
                            open: false,
                        },
                        {
                            text: 'Can Siloho work within any budget?',
                            answer:
                                'Yes, we can work with any budget given to us and promise to deliver the possible in the given budget constraint.',
                            open: false,
                        },
                    ],
                },
                {
                    category: 'Process',
                    questions: [
                        {
                            text:
                                'If I do not have a floor plan of my house in hand, can the Siloho teamwork around it with just photos and videos?',
                            answer:
                                'Yes, we can, but we would strongly recommend a floor plan of the house or if that’s not available we can schedule a visit to serviceable areas where our representative would come and measure the house. Design lies in the details and we don’t compromise on the quality of work delivered to our clients.',
                            open: false,
                        },
                        {
                            text:
                                'Can the Siloho team take up the task of designing multiple rooms at a time?',
                            answer: 'Yes, we can.',
                            open: false,
                        },
                        {
                            text:
                                'How long does it take for the end-to-end process?',
                            answer:
                                'As soon as the design and BOQ is finalised by the client, the execution process takes somewhere in the middle of 30days to 90days depending on the selected product availability and delivery timelines.',
                            open: false,
                        },
                        {
                            text:
                                'How will your interior designers know what exactly I want?',
                            answer:
                                'After taking the style quiz on our platform, your style preference would be matched to a designer on our platform and a design brief call would be set up with her/him. In the design brief call, the designer would ask you more specific questions related to your style preference and vision for your home. Functionality and aesthetics go hand in hand and we always aim to optimise space in the most efficient way possible.',
                            open: false,
                        },
                        {
                            text:
                                'How do I check the progress of my orders for products via Siloho?',
                            answer:
                                'Weekly updates will be given by the site supervisor and a tracking id will be provided to the client. Additionally, the WIP and received delivery photos will be uploaded for the client to keep a track of the progress.',
                            open: false,
                        },
                        {
                            text:
                                'How do I check the progress on execution by different vendors on Siloho?',
                            answer:
                                'Weekly updates will be given by the site supervisor. Additionally, the WIP and received delivery photos will be uploaded for the client to keep a track of the progress.',
                            open: false,
                        },
                        {
                            text:
                                'Will I be updated at every step of my project execution?',
                            answer:
                                'Yes, you will receive a milestone timeline and would be updated after every milestone is achieved apart from the weekly updates.',
                            open: false,
                        },
                        {
                            text:
                                'Do I have to purchase the items I want for interiors right away?',
                            answer:
                                'We would strongly suggest consulting the designer before purchasing as they would be able to cumulate everything in a more aesthetic manner as per your vision for your home.',
                            open: false,
                        },
                        {
                            text:
                                'Can I get revisions even after finalizing the design that I chose? Do you charge additional for that?',
                            answer:
                                'Yes, you can. But we can only take up to 2 design changes after finalising the design as orders would already be in pipeline. More than 2 design changes would be at an additional cost.',
                            open: false,
                        },
                    ],
                },
            ],
        }
    },
    methods: {
        toggleOpen: function(index, categoryNumber) {
            this.faqs[categoryNumber - 1].questions = this.faqs[
                categoryNumber - 1
            ].questions.map((question, i) => {
                if (index === i) {
                    question.open = !question.open
                } else {
                    question.open = false
                }
                return question
            })
        },
    },
    mounted() {
        setTimeout(() => {
            this.loaderShow = false
        }, 1000)
    },
}
</script>

<style lang="scss" scoped>
@import './FAQPage.scss';
</style>
