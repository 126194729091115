<template>
    <div
        :class="open ? 'faq open' : 'faq'"
        @click="$emit('toggleOpen', index, categoryNumber)"
    >
        <div class="question">
            <span class="question-number"
                >{{ categoryNumber }}.{{ questionNumber }}</span
            >{{ faq }}
        </div>
        <div class="answer">
            {{ answer }}
            <!-- <ol style="list-style-type: number">
                    <li>{{ faq.points }}</li>
                </ol> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'FAQAccordion',
    props: [
        'faq',
        'answer',
        'index',
        'open',
        'categoryNumber',
        'questionNumber',
    ],
}
</script>

<style lang="scss" scoped>
@import './FAQAccordion.scss';
</style>
